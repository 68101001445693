import {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import debounce from "./DebounceHelper";

export function useComponentVisible(initialIsVisible, hideOnClickOutside = true) {
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleHideOnEsc = (event) => {
      if (event.key === 'Escape') {
          setIsVisible(false);
      }
  };

  const handleClickOutside = (event) => {
      if (hideOnClickOutside && ref.current && !ref.current.contains(event.target)) {
          setIsVisible(false);
      }
  };

  useEffect(() => {
      document.addEventListener('keydown', handleHideOnEsc, true);
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('keydown', handleHideOnEsc, true);
          document.removeEventListener('click', handleClickOutside, true);
      };
  });

  return { ref, isVisible, setIsVisible };
}

export function useOutsideHandler(ref, handler) {
  useEffect(() => {
    function handleClickOutside(event) {
      if(ref && ref.current
          && !ref.current.contains(event.target)
          && ref.current.offsetParent
          && !ref.current.offsetParent.contains(event.target)) {
        handler();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [ref, handler]);
}

export function useResponsive(handleResizeCustom = () => {}) {
  const [isMobileSmall, setIsMobileSmall] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isLargeDesktop, setIsLargeDesktop] = useState(false);

  function handleResize() {
    setIsMobileSmall(window.innerWidth <= 426)
    setIsMobile(window.innerWidth <= 769);
    setIsTablet(window.innerWidth > 770 && window.innerWidth <= 1025);
    setIsDesktop(window.innerWidth > 1025);
    setIsLargeDesktop(window.innerWidth > 1440);
    handleResizeCustom();
  }

  useLayoutEffect(() => {
    const debouncedHandleResize = debounce(handleResize, 10);
    window.addEventListener("resize", debouncedHandleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  },[]);


  return { isMobileSmall, isMobile, isTablet, isDesktop, isLargeDesktop };
}

export function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)), []
  );

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [ref.current, observer]);

  return isIntersecting;
}

export function usePersistedState(key, initialState) {
  const [state, setState] = useState(() => {
    const storageValue = window.sessionStorage.getItem(key);
    if (storageValue) {
      return JSON.parse(storageValue)
    } else {
      return initialState;
    }
  });

  useEffect(() => {
    window.sessionStorage.setItem(key, JSON.stringify(state))
  }, [key, state]);

  return [state, setState]
}
