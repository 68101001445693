import UserHelper from "../../helpers/UserHelper";
import DateHelper from "../../helpers/DateHelper";
import { types } from "../actions/types";
import Logger from '../../modules/logger';

const initialState = {
	course: null,
  class: null,
  classComments: null,
  classQuiz: null,
};

const courseReducer = (state = initialState, action) => {
	const { COURSE } = types;
  const { type, payload } = action;

  switch (type) {
    case COURSE.SET: {
      const now = new Date();

      payload.modules = Object.values(payload.modules).map((module) => {
        module.isAvailable = module.dtStartAccess ? (new Date(DateHelper.fixDateStringSafari(module.dtStartAccess))) < now : false;
        module.isOver = module.dtEndAccess ? (new Date(DateHelper.fixDateStringSafari(module.dtEndAccess))) < now : false;

        if (!module.isAvailable) {
          Logger.doLog("INFO", "MÓDULO INDISPONÍVEL", { module, user: UserHelper.getLoggedUser() });
        }
        module.active_themes = (module.themes || []).map((theme) => {
          theme.nClassID = payload.nClassID;
          theme.nModuleID = module.nModuleID;
          theme.isAvailable = (new Date(DateHelper.fixDateStringSafari(theme.dtStart))) < now;
          if (!('nQuestionsAnswered' in theme)) theme.nQuestionsAnswered = theme.questionsAnswered;

          if (!theme.isAvailable) {
            Logger.doLog("INFO", "AULA INDISPONÍVEL", { theme, user: UserHelper.getLoggedUser() });
          }

          return theme;
        }).sort((a, b) => parseInt(a.nOrder) >= parseInt(b.nOrder) ? 1 : -1);

        if(Array.isArray(module.active_themes) && module.active_themes.length <= 0) {
          Logger.doLog("INFO", "NENHUMA AULA DISPONÍVEL NO MÓDULO", { module, user: UserHelper.getLoggedUser() });
        }

        return module;
      })

      return {
        ...state,
        course: payload,
      };
    }
    case COURSE.SET_CLASS: {
      return {
        ...state,
        class: payload,
      };
    }
    case COURSE.SET_CLASS_QUIZ: {
      return {
        ...state,
        classQuiz: payload,
      };
    }
    case COURSE.SET_CLASS_LESSON_PROGRESS: {
      const course = { ...state.course };

      course.modules = [...course.modules].map(module => {
        const index = (module.active_themes || []).findIndex(theme => `${theme.nThemeID}` === `${payload.nThemeID}`);
        if (index >= 0) {
          const newModule = {...module};
          newModule.active_themes = [...newModule.active_themes];
          newModule.active_themes[index] = {
            ...payload,
            nClassID: course.nClassID,
            nModuleID: module.nModuleID,
            isAvailable: (new Date(DateHelper.fixDateStringSafari(payload.dtStart))) < (new Date())
          };
          return newModule;
        }
        return module;
      });

      return {
        ...state,
        course
      };
    }
    default:
      return state;
  }
};

export default courseReducer;
