import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
  padding: 4px 0;
  width: 100%;
  max-width: ${(props) => props.theme.box.lg};
  z-index: 2;
  margin: 0 auto;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    max-width: ${(props) => props.theme.box.lg};
    height: 65px;
    bottom: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 800;
    background-color: ${(props) => props.theme.color.background.subMenu};
    display: flex;
    justify-content: center;
    align-items: center;

    ${(props) => props.product === "INVESTMEB3" && MobileB3Container};
  }
  &.invest-me-submenu {
    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      padding-top: 73px !important;
    }
    padding-top: 43px !important;

  }
`;

export const MobileB3Container = css`
  position: sticky;
  height: 58px;
  padding-bottom: 20px;
`;
