import React, { useEffect, useState } from 'react';
import client from './api';
import { useDispatch } from 'react-redux';
import { setLoggedOut } from '../store/actions/auth';
import AlertModal from '../components/shared/AlertModal';
import Logger from '../modules/logger';
import { setCleanUserData } from '../store/actions/user';
import { LoginModal } from '../components/shared/LoginModal';
import { auth } from '../modules/auth';
import { useHistory } from 'react-router';
import EnvHelper from '../helpers/EnvHelper';
import UserHelper from '../helpers/UserHelper';
import StorageHelper from '../helpers/StorageHelper';

export const AxiosInterceptor = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [modalInfo, setModalInfo] = useState({
      title: "global.error",
      description: "global.error",
      confirmButton: "global.confirm",
      cancelButton: "",
      callbackFunc: () => {}
    });
    const [isOpen, setIsOpen] = useState(false);
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    let token;
    let path;
    let hasVideo;

    useEffect(() => {
        const resInterceptor = response => {
          if (UserHelper.isLogged() && EnvHelper.isWL()) {
            token = StorageHelper.getFromLocalStorage('access_token');
            path = history.location.pathname;
            window.addEventListener('click', function(e) {
              const anchorElement = e.target.closest('a');
              if (anchorElement) {
                path = anchorElement.getAttribute('href');
              }
            })

            if (document.querySelector('#video')) {
              hasVideo = true;
            } else {
              hasVideo = false;
            }
          }
          return response;
        }

        const errInterceptor = error => {
          const {
            config = {},
            response: {
              status = '',
            } = {},
            code = '',
          } = error;
          const { url = '' } = config;

          if (code === 'ECONNABORTED' || status === 408) {
            setModalInfo({
              title: "global.error",
              description: "errors.timeout",
              confirmButton: "global.ok",
              callbackFunc: () => { }
            });
            setIsOpen(true);
          } else if (status === 401 && url !== "auth/login") {
            if (!EnvHelper.isInvestMe() && !UserHelper.isLogged()) {
              auth.refreshUserToken(token)
                .then((success) => {
                  if (!hasVideo) {
                    history.go(0);
                  }
                  history.push(path);
                  return success;
                })
                .catch((error) => {
                  UserHelper.removeBearerToken();
                  dispatch(setLoggedOut());
                  dispatch(setCleanUserData());
                  setIsLoginOpen(true);
                  return error;
                })
            }
          } else if (url !== process.env.REACT_APP_LOG_API) {
            Logger.doLogResponse(true, error.request.responseURL, `${error.message};${error.response.data.message}`);
          } else {
            /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
            console.error(`REQUEST ERROR - ${error.message}`);
          }
          return Promise.reject(error);
        }

        const interceptor = client.interceptors.response.use(resInterceptor, errInterceptor);

        return () => client.interceptors.response.eject(interceptor);
    }, []);

    return (
      <>
        { !UserHelper.isLogged() && <LoginModal
          title="errors.expiredSession"
          toggle={() => setIsLoginOpen(false)}
          isShowing={isLoginOpen}
        /> }
        <AlertModal
          style={{ width: "90%" }}
          height="auto"
          isShowing={isOpen}
          toggle={() => setIsOpen(!isOpen)}
          canBeClosed={false}
          fullScreenMobile={false}
          {...modalInfo}
        />
      </>
    );
}
