import styled from "styled-components";

export const Container = styled.div`
  cursor: pointer;
  margin-left: 10px;
  .selected-language {
    transition: all 0.2s ease-out;
    align-items: center;
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 8.5px;
    margin-right: 4px;
    color: ${props => props.theme.color.text};
    border-radius: 4px;
    background-color: ${props => props.theme.color.background.headerItem};

    .active-collapsible {
      transform: rotate(180deg);
    }
  }
  .dropdown-container {
    background-color: ${props => props.theme.color.background.headerItem};
    position: absolute;
    margin-top: 10px;
    border-radius: 4px;
    padding: 8px;
    list-style-type: none;
    li {
      color: ${props => props.theme.color.text};
      padding: 8px;
      img {
        padding-right: 10px;
      }
    }
    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`;
