import styled from "styled-components";

export const MainContainer = styled.main`
  padding: 0;
  margin: 0 auto;
  min-height: calc(100vh - 52px - 82px);

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    ${(props) => {
      if (props.product === "INVESTMEB3") {
        return `
          min-height: 100vh;
          margin-top: ${props.isLoggedIn ? '0px' : 'initial'}
        `;
      } else {
        return `
          min-height: calc(100vh - 52px - 66px);
        `;
      }
    }}
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    min-height: calc(100vh - 146px);
  }
`;
