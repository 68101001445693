export const types = {
  LOGIN: {
    SUCCESS: "LOGIN_SUCCESS",
    LOGOUT: "LOGIN_LOGOUT",
  },
  USER: {
    SUCCESS: "USER_SUCCESS",
    LOGOFF: "USER_LOGOFF",
  },
  LOADING: {
    SET: "LOADING_SET"
  },
  PARTIALPAGELOADING: {
    SET: "PARTIALPAGELOADING_SET"
  },
  COURSES: {
    SET_HOME_COURSES: "COURSES_HOME_SET",
  },
  COURSE: {
    SET: "COURSE_SET",
    SET_CLASS: "COURSE_SET_CLASS",
    SET_CLASS_COMMENTS: "COURSE_SET_CLASS_COMMENTS",
    SET_CLASS_QUIZ: "COURSE_SET_CLASS_QUIZ",
    SET_CLASS_LESSON_PROGRESS: "COURSE_SET_CLASS_LESSON_PROGRESS",
    UPDATE_CLASS_COMMENTS : "COURSE_UPDATE_CLASS_COMMENTS"
  },
  PURCHASES: {
    SET: "PURCHASES_SET",
    CANCEL: "PURCHASES_CANCEL",
  },
  NOTIFICATIONS: {
    SET: "NOTIFICATIONS_SET",
    CLEAR: "NOTIFICATIONS_CLEAR",
    CLEAR_ALL: "NOTIFICATIONS_CLEAR_ALL",
    SET_NO_NEW: "NOTIFICATIONS_SET_NO_NEW"
  },
  VOUCHER: {
    SET: "VOUCHER_SET"
  },
  COMMENTS: {
    SET_COMMENTS: "COMMENTS_SET_COMMENTS",
    ADD_NEW_COMMENT : "COMMENTS_ADD_NEW_COMMENT",
    ADD_NEW_REPLY: "COMMENTS_ADD_NEW_REPLY",
    UPDATE_COMMENT: "COMMENTS_UPDATE_COMMENT",
    UPDATE_REPLY: "COMMENTS_UPDATE_REPLY",
    DELETE_COMMENT: "COMMENTS_DELETE_COMMENT",
    DELETE_REPLY: "COMMENTS_DELETE_REPLY",
    ADD_NEW_PAGE: "COMMENTS_ADD_NEW_PAGE",
  },
  COURSELP: {
    SET_COURSE_NAME: "COURSELP_SET_COURSE_NAME",
  },
  APP: {
    SET_IS_PLAYLIST: "SET_IS_PLAYLIST",
    SET_PLAYLIST: "SET_PLAYLIST",
    SET_IN_CONTENT: "SET_IN_CONTENT",
    SET_SCROLL_POSITION: "SET_SCROLL_POSITION",
    SET_SHOW_HIDDEN_SUBMENU: "SET_SHOW_HIDDEN_SUBMENU",
    SET_SHOW_HEADER:"SET_SHOW_HEADER",
  },
  LOCATION: {
    SUCCESS: 'SET_LOCATION',
  },
};
