const b3 = {
  title: "b3",
  isInvestMe: true,
  director: "Rodrigo Paiva",
  name: "Hub3",

  var: {
    showInHeader: false,
    navIcon: false,
    navItem: false,
    directorSignature: {
      top: '-50px',
      left: '-60px',
      width: '200px',
    },
    homeTabletMaxWidth: '642px'
  },

  homeInformation: {
    cardOne: 'homeInformation.cardOne',
    cardTwo: 'homeInformation.cardTwo',
    cardThree: 'homeInformation.cardThree'
  },

  header: {
    blogLink: '',
    blogName: '',
    helpName: '',
    helpLink: '',
  },
  
  aboutUs: {
    preface: 'preface',
    title: 'title',
    subtitle: 'subtitle',
  },

  knowTeam: {
    title: 'knowTeam.title',
    subtitle: 'knowTeam.subtitle'
  },

  imgs: {
    homeVideoBg: '/info_video_home.svg',
    homeVideoThumb: 'https://i.vimeocdn.com/video/1480646872-b7e5e57a93f58e1c67298a50a1c6019b76519feca505779f2896e5bfeff1124e-d?mw=1000&mh=563',
    homeVideo: 'https://player.vimeo.com/video/731934018?h=8df42a303a&amp;badge=0&amp;autoplay=1;autopause=0&amp;player_id=0&amp;app_id=58479',
    unavailableContent: "url('/backgroundUnavailable.png')",
    unavailableContentMobile: "url('/bkgUnavailableMobileBottom.png'), url('/bkgUnavailableMobileTop.png')",
    register: "url('/RegisterCover.svg')",
    defaultPerfil: "/themes/investmeb3/default-perfil.png",
    directorSignature: '/director-signature.png',
    homeTablet: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/prancheta.webp',
  },

  breakpoints: {
    xs: "376px",
    sm: "426px",
    md: "769px",
    lg: "1025px",
    //xl: "1440px",
    //xxl: "1921px",
    xl: "1367px",
    xxl: "1440px",
    xxxl: "1921px",
  },

  box: {
    md: "780px",
    lg: "1366px",
  },

  font: {
    family: {
      site: "Roboto",
      title: "Roboto",
    },
    families: "Roboto:ital,300,400,500,600,700",
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
    size: {
      xxxs: "10px",
      xxs: "12px",
      xs: "14px",
      sm: "16px",
      md: "20px",
      lg: "24px",
      xl: "32px",
      xxl: "40px",
      xxxl: "48px",
    },
    custom: {
      size: {
        custom01: "18px"
      }
    }
  },

  border: {
    radius: {
      none: "0px",
      sm: "4px",
      md: "8px",
      lg: "20px",
      rounded: "100px",
      circular: "50%",
    },
  },

  color: {
    brand: {
      pure: "#30F3F3",
      dark: "#6863F5",
      darkest: "#0aa9a9",
      light: "#56f5f5",
    },
    gradient: {
      aboutUs: "linear-gradient(98deg, #b1b9be 22%, #283345 82%)",
      banner: 'linear-gradient(180deg, rgba(2, 12, 19, 0) 0%, rgba(2, 12, 19, 0.8) 63.54%, #020c13 100%)',
      bannerMobile: 'linear-gradient(180deg, rgba(2, 12, 19, 0) 0%, rgba(2, 12, 19, 0.8) 48.44%, #020c13 100%)',
    },
    neutral: {
      low: {
        pure: "#0B0E12",
        darkestplus: "#141A22",
        darkest: "#212A39",
        dark: "#19202B",
        medium: "#283345",
        light: "#2F3C52",
        lightest: "#b1b9be",
      },
      high: {
        pure: "#FFFFFF",
        lightest: "#B9CBE5",
        light: "#A6B5CB",
        medium: "#384B66",
        dark: "#5F707B",
        darkest: "#45545C",
      },
    },
    feedback: {
      success: "#30F3F3",
      warning: "#FEE60B",
      danger: "#EB5948",
      dangerLight: "#FF5A5A",
      dangerDark: "#AB1A31",
      message: "#a6b2ba",
    },
    background: {
      body: "#19202B",
      imageInfoSection: "#19202B",
      loader: "#19202B",
      unavailableContent: "#19202B",
      infoCards: "#283345",
      card: "#283345",
      checkout: "#283345",
      courseCard: "#283345",
      cardDivider: "#b1b9be",
      input: "#19202B",
      inputSearch: "#19202B",
      result: "#283345",
      tab: "#19202B",
      subMenu: "#19202B",
      modal: "#283345",
      footer: "#283345",
      header: "#283345",
      headerItem: "#2F3C52",
      infoBar: "#283345",
      knowTeam: "#19202B",
      bottomBar: "#2F3C52",
      cardImage: "#283345",
      nav: "#FFFFFF",
      textArea: "#283345",
      homeIndicator: "#868E96",
      menu: "#2F3C52",
      progressBar: "#0B0E12",
      textInputDisabled: "#19202B",
      textInput: "#212A39",
      banner: '#020c13',
      courseLP: '#212A39',
      parallaxCourseLP: '#212A39',
      compareBlock: "#19202B",
      subscriptionContent: "#283345",
      inputSearchContainer: "#283345",
      progressColor: "#0B0E12",
      collapsible: "#283345",
      moduleIndex: "#19202B",
      moduleIndexOpen: "#212A39",
      buttonDark: "#19202B",
      buttonDarkHover: "#212A39",
    },
    mobile: {
      background: "#283345",
      title: "#FFFFFF",
      subtitle: "#FFFFFF",
      header: "#19202B",
      couseModuleHeader: "#283345",
      card: "#19202B",
      collapsible: "#A6B5CB",
      homeButton: "#FFFFFF",
      message: "#a6b2ba",
      inputSearch: "#283345",
    },
    // CUSTOM
    backgroundDegrade: "linear-gradient(to bottom, rgba(73, 73, 73, 0), #1f1f1f 110%)",
    transparent: "transparent",
    primary: "#30F3F3",
    title: "#FFFFFF",
    secondary: "#FFFFFF",
    subtitle: "#FFFFFF",
    moduleIndex: "#FFFFFF",
    subtitleCustom: "#a6b2ba",
    description: "#5F707B",
    price: "#FFFFFF",
    label: "#A6B5CB",
    text: "#384B66",
    textCustom: "#b1b9be",
    secondaryText: "#909ba2",
    home: "#B9CBE5",
    header: "#B9CBE5",
    textLight: "#A6B5CB",
    cardHeader: "#FFFFFF",
    cardPrice: "#B9CBE5",
    cardText: "#30F3F3",
    cardTextCheckout: "#b1b9be",
    stepNumber: "#283345",
    infoLineTitle: "#B9CBE5",
    border: "#b1b9be",
    tabBorder: "#2F3C52",
    navBorder: "#2F3C52",
    headerBorder: "#283345",
    collapsibleBorder: 'transparent',
    textAreaBorder: "#283345",
    menuBorder: "#5F707B",
    textInputBorder: "#19202B",
    cardBorder: "#FFFFFF",
    quiz: "#B9CBE5",
    quizDarkest: "#45545C",
    tabColor: "#FFFFFF",
    navColor: "#FFFFFF",
    tabBar: "#B9CBE5",
    darkSpan: "#909ba2",
    loginTitle: "#B9CBE5",
    message: "#B9CBE5",
    messageCustom: "#a6b2ba",
    formTitle: "#B9CBE5",
    navLink: "#A6B5CB",
    courseCard: "#a6b2ba",
    errorModal: "#a6b2ba",
    span: "#FFFFFF",
    stroke: "#0B0E12",
    strokeLight: "#A6B5CB",
    collapsible: "#FFFFFF",
    comment: "#b9c1c6",
    unavailableContent: "#909ba2",
    courseProgress: "#A6B5CB",
    cardInfoCalendar: "#A6B5CB",
    courseLP: "#FFFFFF",
    infoSection: "#A6B5CB",
    continueText: "#A6B5CB",
    filterOptions: "#b1b9be",
    classIC: "#b1b9be",
    headerTable: "#a6b2ba",
    inputSearch: "#283345",
    progressColor: "#384B66",
    progressText: "white",
    liveNow: "#FF5A5A",
    blackStripe: "#141A22",
    iconColor: "#A6B5CB",
    searchIconColor: "#FFFFFF",
    inputColor: "#FFFFFF",
    placeHolderColor: "#A6B5CB",
    titleContainerText: "#A6B5CB",
    copyrightText: "#B9CBE5",
    iconColorMobile: "#FFFFFF",
    iconColorMobileCustom: "#FFFFFF",
    userHome: "#FFFFFF",
    nav: "#B9CBE5",
    buttonDarkIcon: "#A6B5CB",
  },
};

export default b3;
