import styled from 'styled-components';
import { Input } from '../shared/TextInput/styles';

export const Title = styled.div`
  text-align: ${(props) => (props.centerText ? "left" : "center")};
  ${(props) => {
    if(props.isCheckout) {
      return `padding: 0px 14px 16px 0px;`;
    }
    return `padding: 16px 14px 40px 14px;`;
  }}
  font-weight: ${(props) => props.theme.font.weight.medium};
  font-size: ${(props) => props.theme.font.size.lg};
  color: ${(props) => props.theme.color.loginTitle};
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 0 0 32px 0;
    &.title-login {
      font-size: ${(props) => {
        if (props.centerText) {
          return props.theme.font.size.sm;
        } else {
          return props.theme.font.size.lg;
        }
      }};
    }
  }
`;

export const Container = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  width: 100%;
  min-width: ${props => props.isModalLogin ? "none" : "auto"};
  max-width: ${(props) => {
    if (props.isCheckout) {
      return "100%";
    }
    return "516px";
  }};
  background: ${(props) => props.theme.isInterInvest ? props.theme.color.background.body : props.theme.color.background.card};

  @media (max-width: ${(props) => props.theme.breakpoints.lg}) {
    border-radius: ${(props) => { if(props.isCheckout) return "0px 0px 8px 8px"} };
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    height: ${(props) => {
      if (!props.isCheckout && !props.isModalLogin) {
        return "calc(100vh - 64px)";
      }
    }};
    width: 100%;
    border-radius: 0;
    background: ${(props) => {
      if (props.isCheckout) {
        return props.theme.color.neutral.low.medium;
      }
      return props.theme.color.neutral.low.dark;
    }};
    justify-content: flex-start;
    border-radius: ${(props) => {
      if (props.isCheckout) {
        return "0px";
      }
      return "8px";
    }};
  }

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    ${(props) => {
      if (props.theme.isWL) {
        return `background: ${props.theme.color.neutral.high.pure}`
      }
    }};
    ${Input} {
      ${(props) => {
        if (props.theme.isWL) {
          `background-color: ${props.theme.color.neutral.low.dark};`
        }
      }}
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    border-radius: ${(props) => { if(props.isCheckout) return "8px"} };
  }

  ${(props) => {
    if (!props.isAuthentication) {
      return `
        @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
          max-width: 680px;
        }
      `;
    }
  }};
`;
export const AlertContainer = styled.div`
  background: ${(props) => props.theme.isInterInvest ? props.theme.color.feedback.danger : props.theme.color.feedback.dangerDark};
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 10px 10px 0px 0px;
  position: relative;
  justify-content: center;
  align-items: center;

  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    border-radius: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.md}) and (max-width: ${(props) => props.theme.breakpoints.lg}) {
    border-radius: 0;
    position: fixed;
    left: 0;
    top: 64px;
  }
`;
export const MessageErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.color.neutral.high.pure};
  font-size: ${(props) => props.theme.font.size.xs};
  line-height: 14px;

  span {
    display: inline-block;
    margin-left: 4px;
    margin-top: 0.7%;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px;
  @media (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 32px 16px;

    ${Input} {
      ${(props) => {
        if (!props.isCheckout) {
          `background-color: ${props.theme.color.neutral.low.medium};
          border: 4px solid ${props.theme.color.neutral.low.medium};
          border-radius: ${props.theme.border.radius.sm};`
        }
      }}
    }
    span {
      &.description-checkout {
        font-size: ${(props) => props.theme.font.size.xs};
      }
    }
  }

  span {
    &.description-checkout {
      padding: 0px 10px 32px 0px;
      font-weight: ${(props) => props.theme.font.weight.regular};
      font-size: ${(props) => props.theme.font.size.sm};
      ${(props) => {
        if (props.product === "INVESTMEB3") {
          return `color: ${props.theme.color.neutral.high.light};`;
        } else {
          return `color: ${props.theme.color.neutral.high.medium};`;
        }
      }}
    }
    b {
      color: ${(props) => props.theme.color.primary};
    }
  }
`;
export const ButtonLogin = styled.div`
  &:hover {
    span {
      text-decoration: underline;
    }
  }
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  span {
    margin-left: 10px;
    font-size: ${(props) => props.theme.font.size.xs};
    color: ${(props) => props.theme.color.subtitle};
  }
`;
export const DivButton = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 26px;
`;
export const LineWithMiddleLine = styled.div`
  margin: 16px 0;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-column-gap: 8px;
  align-items: center;
  text-transform: uppercase;
  font-size: ${(props) => props.theme.font.size.xxs};
  color: ${(props) => props.theme.color.infoLineTitle};

  &::before,
  &::after {
    content: "";
    height: 1px;
    display: block;
    background-color: ${(props) => props.theme.color.neutral.high.darkest};
    opacity: 0.2;
  }
`;
export const TermsReserved = styled.span`
  text-align: center;
  margin-top: 1.5rem;
  font-size: ${(props) => props.theme.font.size.xxs};
  a {
    text-decoration: none;
    color: ${(props) => props.theme.color.neutral.high.pure};
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 12px;
  cursor: pointer;
`
export default Container;
