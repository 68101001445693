const MoneyHelper = {
  calculateVoucherDiscount(voucher, amount) {
    let discount = 0;
    if (voucher && voucher.campaignDiscountData && voucher.campaignType === "discount") {
      if (voucher.campaignDiscountData.discountType === "0") {
        discount = parseFloat(voucher.campaignDiscountData.discount);
      } else if (voucher.campaignDiscountData.discountType === "1") {
        discount = amount * (parseFloat(voucher.campaignDiscountData.discount) / 100);
      }
    }
    return discount;
  },

  formatPrice(price) {
    return price.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
    });
  },

  calcInstallmentValue(price, installment) {
    const _installment = parseFloat(price) / parseInt(installment, 10);
    return this.formatPrice(_installment);
  },

  getInstallmentsArray(data, voucher) {
    const numberOfInstallmets = parseInt(data.nParcelas);
    let fValorParcelado = parseFloat(data.fValorParcelado);
    let atSign = parseFloat(data.fValorAVista);
    if (voucher) {
      fValorParcelado = fValorParcelado - this.calculateVoucherDiscount(voucher, fValorParcelado);
      atSign = atSign - this.calculateVoucherDiscount(voucher, atSign);
    }
     const installments = [...Array(numberOfInstallmets).fill(fValorParcelado)];

     return installments.map((item, i) => {
      const installment = i + 1;
      if (installment === 1) {
        return {
          value: installment,
          text: `${installment}x de ${this.formatPrice(atSign)}`,
        };
      } else {
        return {
          value: installment,
          text: `${installment}x de ${this.formatPrice(item / installment)}`,
        };
      }
    });
  }
}

export default MoneyHelper;
