const dark = {
  title: "dark",
  director: "Rodrigo Paiva",
  name: "Nelogica",

  var: {
    showInHeader: true,
    navIcon: true,
    navItem: true,
    directorSignature: {
      top: '-50px',
      left: '-60px',
      width: '200px',
    },
    homeTabletMaxWidth: '642px'
  },

  homeInformation: {
    cardOne: 'homeInformation.cardOne',
    cardTwo: 'homeInformation.cardTwo',
    cardThree: 'homeInformation.cardThree'
  },

  header: {
    blogLink: 'https://portal.invest.academy/',
    blogName: 'headerMenu.news',
    helpName: 'headerMenu.help',
    helpLink: 'https://investacademy.zendesk.com/hc/pt-br',
  },

  aboutUs: {
    preface: 'preface',
    title: 'title',
    subtitle: 'subtitle',
  },

  knowTeam: {
    title: 'knowTeam.title',
    subtitle: 'knowTeam.subtitle'
  },

  imgs: {
    homeVideoBg: '/info_video_home.svg',
    homeVideoThumb: 'https://i.vimeocdn.com/video/1480646872-b7e5e57a93f58e1c67298a50a1c6019b76519feca505779f2896e5bfeff1124e-d?mw=1000&mh=563',
    homeVideo: 'https://player.vimeo.com/video/731934018?h=8df42a303a&amp;badge=0&amp;autoplay=1;autopause=0&amp;player_id=0&amp;app_id=58479',
    unavailableContent: "url('/backgroundUnavailable.png')",
    unavailableContentMobile: "url('/bkgUnavailableMobileBottom.png'), url('/bkgUnavailableMobileTop.png')",
    register: "url('/RegisterCover.svg')",
    defaultPerfil: "/themes/investacademy/default-perfil.png",
    directorSignature: '/director-signature.png',
    homeTablet: 'https://downloadserver-cdn.nelogica.com.br/content/educational/images/prancheta.webp',
    vdpHero: '/assets/images/vdp/hero.webp',
  },

  breakpoints: {
    xs: "376px",
    sm: "426px",
    md: "769px",
    lg: "1025px",
    xl: "1440px",
    xxl: "1921px",
  },

  box: {
    md: "780px",
    lg: "1366px",
  },

  font: {
    family: {
      site: "Roboto",
      title: "Roboto",
    },
    families: "Roboto:ital,300,400,500,600,700",
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
    size: {
      xxxs: "10px",
      xxs: "12px",
      xs: "14px",
      sm: "16px",
      md: "20px",
      lg: "24px",
      xl: "32px",
      xxl: "40px",
      xxxl: "48px",
    },
    custom: {
      size: {
        custom01: "18px"
      }
    }
  },

  border: {
    radius: {
      none: "0px",
      sm: "4px",
      md: "8px",
      lg: "20px",
      rounded: "100px",
      circular: "50%",
    },
  },

  color: {
    brand: {
      pure: "#FC440F",
      dark: "#E74310",
      darkest: "#A92C17",
      light: "#FF603B",
    },
    gradient: {
      aboutUs: "linear-gradient(98deg, #1C384A 22%, #0A202E 82%)",
      banner: 'linear-gradient(180deg, rgba(2, 12, 19, 0) 0%, rgba(2, 12, 19, 0.8) 63.54%, #020c13 100%)',
      bannerMobile: 'linear-gradient(180deg, rgba(2, 12, 19, 0) 0%, rgba(2, 12, 19, 0.8) 48.44%, #020c13 100%)',
    },
    neutral: {
      low: {
        pure: "#000000",
        darkest: "#020C13",
        dark: "#031520",
        medium: "#0A202E",
        light: "#162D3B",
        lightest: "#1C384A",
      },
      high: {
        pure: "#FFFFFF",
        lightest: "#F2F4F5",
        light: "#D4DADD",
        medium: "#99A5AC",
        dark: "#5F707B",
        darkest: "#45545C",
      },
    },
    feedback: {
      success: "#00DDAD",
      warning: "#FEE60B",
      danger: "#F75158",
      dangerLight: "#FF7379",
      dangerDark: "#AB1A31",
      message: "#99A5AC",
    },
    background: {
      body: "#031520",
      imageInfoSection: "#031520",
      loader: "#031520",
      unavailableContent: "#031520",
      infoCards: "#0A202E",
      card: "#0A202E",
      checkout: "#0A202E",
      courseCard: "#0A202E",
      cardDivider: "#1C384A",
      input: "#031520",
      inputSearch: "#162D3B",
      result: "#0A202E",
      tab: "#031520",
      subMenu: "#031520",
      modal: "#0A202E",
      footer: "#0A202E",
      header: "#0A202E",
      headerItem: "#162D3B",
      infoBar: "#162D3B",
      knowTeam: "#0A202E",
      bottomBar: "#162D3B",
      cardImage: "#162D3B",
      nav: "#FFFFFF",
      textArea: "#0A202E",
      homeIndicator: "#868E96",
      menu: "#162D3B",
      progressBar: "#000000",
      textInputDisabled: "#162D3B",
      textInput: "#031520",
      banner: '#020c13',
      courseLP: 'rgb(10, 32, 46)',
      parallaxCourseLP: '#020C13',
      compareBlock: "#162D3B",
      subscriptionContent: "#162D3B",
      inputSearchContainer: "#162D3B",
      progressColor: "#000000",
      collapsible: "#162D3B",
      moduleIndex: "#0A202E",
      moduleIndexOpen: "#0A202E",
      buttonDark: "#031520",
      buttonDarkHover: "#020C13",
      homeV2Hero: "linear-gradient(74.59deg, #0B161D 0%, #072233 100%)",
      homeV2Curses: "#1B1B1B",
      homeV2Section: "#0F1214",
    },
    mobile: {
      background: "#0A202E",
      title: "#FFFFFF",
      subtitle: "#FFFFFF",
      header: "#031520",
      couseModuleHeader: "#162D3B",
      card: "#031520",
      collapsible: "#D4DADD",
      homeButton: "#99A5AC",
      message: "#99A5AC",
      inputSearch: "#162D3B",
    },
    // CUSTOM
    backgroundDegrade: "linear-gradient(to bottom, rgba(73, 73, 73, 0), #1f1f1f 110%)",
    transparent: "transparent",
    primary: "#FC440F",
    title: "#FFFFFF",
    secondary: "#FFFFFF",
    subtitle: "#FFFFFF",
    moduleIndex: "#FFFFFF",
    subtitleCustom: "#a6b2ba",
    description: "#5F707B",
    price: "#FFFFFF",
    label: "#D4DADD",
    text: "#99A5AC",
    textCustom: "#b1b9be",
    secondaryText: "#909ba2",
    home: "#F2F4F5",
    header: "#F2F4F5",
    textLight: "#D4DADD",
    cardHeader: "#FFFFFF",
    cardPrice: "#F2F4F5",
    cardText: "#FC440F",
    cardTextCheckout: "#b1b9be",
    stepNumber: "#0A202E",
    infoLineTitle: "#F2F4F5",
    border: "#1C384A",
    tabBorder: "#162D3B",
    navBorder: "#162D3B",
    headerBorder: "#162D3B",
    collapsibleBorder: "#162D3B",
    textAreaBorder: "#0A202E",
    menuBorder: "#5F707B",
    textInputBorder: "#162D3B",
    cardBorder: "#FFFFFF",
    quiz: "#F2F4F5",
    quizDarkest: "#45545C",
    tabColor: "#FFFFFF",
    navColor: "#FFFFFF",
    tabBar: "#FFFFFF",
    darkSpan: "#909ba2",
    loginTitle: "#F2F4F5",
    message: "#F2F4F5",
    messageCustom: "#a6b2ba",
    formTitle: "#F2F4F5",
    navLink: "#909ba2",
    courseCard: "#a6b2ba",
    errorModal: "#a6b2ba",
    span: "#FFFFFF",
    stroke: "#000000",
    strokeLight: "#D4DADD",
    collapsible: "#F2F4F5",
    comment: "#b9c1c6",
    unavailableContent: "#909ba2",
    courseProgress: "#99A5AC",
    cardInfoCalendar: "#99A5AC",
    courseLP: "#F2F4F5",
    infoSection: "#99A5AC",
    continueText: "#b9c1c6",
    filterOptions: "#b1b9be",
    classIC: "#1C384A",
    headerTable: "#a6b2ba",
    progressColor: "#5F707B",
    progressText: "white",
    liveNow: "#FF7379",
    iconColor: "#99A5AC",
    searchIconColor: "#FFFFFF",
    inputColor: "#F2F4F5",
    placeHolderColor: "#99A5AC",
    titleContainerText: "#99A5AC",
    copyrightText: "#99A5AC",
    iconColorMobile: "#FFFFFF",
    iconColorMobileCustom: "#FFFFFF",
    userHome: "#FFFFFF",
    nav: "#F2F4F5",
    buttonDarkIcon: "#D4DADD",
    homeV2VideoBoxShadow: "4px 6px 80px 0px #03030699",
    homeV2InfoCard: "#191D20CC",
    homeV2InfoCardBoxShadow: "0px 4px 76px 0px #00000099, inset 0px 10px 24px 0px #21212166",
    homeV2CommentsDot: "#3D3D3D",
    homeV2CommentsContent: "#272727",
    homeV2CommentsContentBoxShadow: "0px 4px 76px 0px #00000099, inset 0px 10px 24px 0px #21212166",
  },
};

export default dark;
