import { types } from "../actions/types";

const initialState = {
  user: {
      id: 0,
      name: '',
      lastName: '',
      email: '',
      phone: '',
  }
};

const userReducer = (state = initialState, action) => {
  const { USER } = types;
  const { type, payload } = action;

  switch (type) {
    case USER.SUCCESS:
      return {
        ...state,
        user: payload,
      };
      case USER.LOGOFF: {
      return {
        ...state,
        user: initialState.user,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
