/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef, Suspense, lazy } from "react";
import { Container, LeftHeader, CenterHeader, RightHeader, LogoContainer, HeaderItem, SearchInputContainer, CheckoutIcon, CancelButton, SandwichContainer, HeaderTitleText, GoBackContainer, ReturnButton } from "./styles";
import SvgImage from '../../handlers/HandleSvg'
import InputSearch from "../InputSearch";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { Button } from "../shared/Button";
import { useDispatch, useSelector } from "react-redux";
import { useComponentVisible, useResponsive } from "../../helpers/HooksHelper";
import {notifications as notificationsApi} from '../../modules/notifications';
import { setNoNewNotifications, setNotifications } from "../../store/actions/notifications";
import Logger from "../../modules/logger";
import EnvHelper from "../../helpers/EnvHelper";
import { product } from "../../styles/theme/themes";
import { setLoading } from "../../store/actions/loading";
import Svg from "../../handlers/HandleSvg";
import LocaleSwitcher from "../LocaleSwitcher";

const MenuUser = lazy(() => import("../shared/MenuUser"));
const MenuNotifications = lazy(() => import("../shared/MenuNotifications"));

const THIRTY_MINUTES_MS = 30 * 60 * 1000;

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {pathname} = useLocation();
  const {isExact} = useRouteMatch();
  const { t, i18n: {language} } = useTranslation("translations");
  const themeContext = useContext(ThemeContext);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { scrollBlock, showHiddenSubMenu, isPlaylist, inContent, showHeader } = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.user);
  const { courseLpName } = useSelector((state) => state.courseLp);
  const [inputOpen, setInputOpen] = useState(false);
  const [accountIconColor, setAccountIconColor] = useState("");
  const headerContainerRef = useRef(null);
  const { isMobile, isTablet, isLargeDesktop } = useResponsive();
  const { hasNew } = useSelector((state) => state.notifications);
  const regex = /(\/[a-zA-Z]{2}\/)([a-zA-Z]{7}\/)([0-9]+)/gm;

  const { ref: userMenuRef, isVisible: isUserMenuOpen, setIsVisible: setUserMenuOpen  } = useComponentVisible(false, isLargeDesktop);
  const { ref: notificationsRef, isVisible: isNotificationsOpen, setIsVisible: setNotificationsOpen  } = useComponentVisible(false);
  const { ref: localeSwitchRef, isVisible: isLocaleSwitchOpen, setIsVisible: setLocaleSwitchOpen  } = useComponentVisible(false);

  const refNotificationsTimer = useRef(null);
  const logData = {
    user: user.id,
    time: new Date().toLocaleString(),
    browser: EnvHelper.browserDetect(),
  }

  const loadNotifications = async () => {
    const resp = await notificationsApi.getNotifications();

    if(!resp.success) {
      Logger.doLog("ERROR", "Ocorreu um erro na busca por notificações!", { ...logData, resp });
      return;
    }
    dispatch(setNotifications(resp.data));
  };

  useEffect(() => {
    const { pathname = '' } = history.location;
    if (pathname.includes('courses')
      && pathname.includes('modules')
      && pathname.includes('classes')) {
        headerContainerRef.current.style.position = '';
    } else {
      headerContainerRef.current.style.position = 'sticky';
    }
  }, [history])

  useEffect(() => {
    if (isLoggedIn && !EnvHelper.isInvestMe()) {
      loadNotifications();

      refNotificationsTimer.current = setInterval(() => {
        loadNotifications();
      }, THIRTY_MINUTES_MS);

      return () => {
        if (refNotificationsTimer.current) {
          clearInterval(refNotificationsTimer.current);
        }
      };
    }
  }, []);

  let lastScrollTop = 0;
  useEffect(() => {
    const app = document.querySelector('.App');
    function handleScroll() {
      const currentScroll = app.scrollTop;

      const isHidden = (currentScroll > lastScrollTop && currentScroll > 300) && window.innerWidth <= 992;
      lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
      headerContainerRef.current.style.top = isHidden ? '-55px' : '0';
    }

    app.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => app.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (isMobile) {
      setInputOpen(false);
      setAccountIconColor(themeContext.color.neutral.high.lightest);
    } else {
      setAccountIconColor(themeContext.color.primary);
    }
  }, [isMobile, themeContext.color.primary, themeContext.color.neutral.high.lightest, accountIconColor]);

  const isAuth = pathname.includes('/login') || pathname.includes('/register') || pathname.includes('/recuperacao-senha')  || pathname.includes('/password-recovery') || pathname.includes('/routing-password-recovery');
  const isCheckout = pathname.includes('/checkout');

  const getNotificationColor = () => {
    if(!isMobile) {
      return themeContext.color.primary;
    }

    return isNotificationsOpen ? themeContext.color.primary : themeContext.color.neutral.high.dark
  }


  const handleMenu = () => {
    if(EnvHelper.isInvestMe()) {
      window.location.href='https://openSideMenu/';
    }
    else if(isLoggedIn) {
      setUserMenuOpen(!isUserMenuOpen);
      setNotificationsOpen(false);
    } else {
      history.push(`/${language}/login`);
      setUserMenuOpen(false);
    }
  }

  const arrName = user.name.split(" ");
  const userName = arrName[0];

  const handleToggleNotification = () => {
    setNotificationsOpen(!isNotificationsOpen);
    setUserMenuOpen(false);
    if (hasNew) dispatch(setNoNewNotifications());
  }
  const showLoginButton = !EnvHelper.isInvestMe() || !EnvHelper.isPrd();
  const LoggedInButtons = () => (
    <React.Fragment>
      {product === "INVESTACADEMY" && <HeaderItem
        onClick={handleToggleNotification}
        active={isNotificationsOpen}
      >
        <SvgImage iconClass={hasNew ? "new-notification" : ""} name="ia-notification-ic" color={getNotificationColor()} width="24px" height="24px" />
        <span>{t("header.notification")}</span>
      </HeaderItem>}
      {(showLoginButton) && <HeaderItem onClick={() => handleMenu()} active={isUserMenuOpen}>
        <SvgImage name="ic-account-logged" color={EnvHelper.isWL() ? themeContext.color.neutral.low.medium : accountIconColor} width="24px" height="24px" />
        <span>{userName}</span>
      </HeaderItem>}
    </React.Fragment>
  );

  const NotLoggedInButtons = () => (
    <React.Fragment>
      {!inputOpen && showLoginButton && !EnvHelper.isInvestMe() &&
      <>
      <HeaderItem onClick={() => handleMenu()} >
        <SvgImage
          name="ic-account"
          color={accountIconColor}
          width="24px"
          height="24px"
        />
        <span>{t("headerNoUser.login")}</span>
      </HeaderItem>

       <Button
        className="register-button"
        action={() => history.push(`/${language}/register`)}
        size="sm"
      >
        {t("headerNoUser.register")}
      </Button>
      </>}
    </React.Fragment>
  );

  const regularRightSide = () => {
    return (
      <>
        {
          ((!pathname.includes("/courselp") && EnvHelper.isInvestMe()) || !EnvHelper.isInvestMe()) &&
          <RightHeader expanded={inputOpen}>
            {inputOpen && EnvHelper.isInvestMe() && (
              <ReturnButton>
                <Svg
                    name="ic-arrow-left"
                    width="20px"
                    height="20px"
                    onClick={() => setInputOpen(false)}
                />
              </ReturnButton>
            )}
            {inputOpen && !EnvHelper.isInvestMe() && (
              <CancelButton onClick={() => setInputOpen(false)}>{t('global.cancel')}</CancelButton>
            )}
            <SearchInputContainer isInvestMe={EnvHelper.isInvestMe()} expanded={inputOpen} >
              <SvgImage
                name="ic-search"
                width={product === "INVESTMEB3" ? "20px" : "24px"}
                height={product === "INVESTMEB3" ? "20px" : "24px"}
                color={ themeContext.color.searchIconColor }
                onClick={() => setInputOpen(!inputOpen)}
              />
              {inputOpen &&<InputSearch hideIcon setInputOpen={setInputOpen} inputOpen={inputOpen} />}
            </SearchInputContainer>
            {!inputOpen && !isMobile &&(
              <React.Fragment>
                {themeContext.header?.blogLink ? <HeaderItem className="blog-link">
                  <a
                    href={themeContext.header.blogLink}
                    target={EnvHelper.isInvestMe() ? "_self" : "_blank"}
                    rel="noreferrer"
                  >
                    <SvgImage
                      name="ia-news-ic"
                      color={accountIconColor}
                      width="24px"
                      height="24px"
                    />
                    <span>{t(themeContext.header.blogName)}</span>
                  </a>
                </HeaderItem> : null}
                {themeContext.header.helpLink ? <HeaderItem className="blog-link">
                  <a
                    href={themeContext.header.helpLink}
                    target={EnvHelper.isInvestMe() ? "_self" : "_blank"}
                    rel="noreferrer"
                  >
                    <SvgImage
                      name="ia-alert_question-ic"
                      color={accountIconColor}
                      width="24px"
                      height="24px"
                    />
                    <span>{t(themeContext.header.helpName)}</span>
                  </a>
                </HeaderItem> : null}
              </React.Fragment>
            )}
            { !isMobile ? isLoggedIn ? <LoggedInButtons /> : <NotLoggedInButtons />:false }
            {!isMobile && <LocaleSwitcher ref={localeSwitchRef}  open={isLocaleSwitchOpen} setOpen={setLocaleSwitchOpen}></LocaleSwitcher>}
          </RightHeader>

        }
      </>
    );
  }

  const checkoutRightSide = () => {
    return (
      <RightHeader checkout={true}>
        <HeaderItem checkout={true}>
          <CheckoutIcon>
            <SvgImage name="ia-shield-ic" width="18px" height="18px" color={themeContext.color.feedback.success} />
          </CheckoutIcon>
          <span>{t("header.checkout")}</span>
        </HeaderItem>
      </RightHeader>
    );
  }

  const authRightSide = () => {
    let appLink = "https://play.google.com/store/apps/details?id=com.nelogica.invest.android.prod";
    if(navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPad') || navigator.userAgent.includes('iPod')) {
       appLink = "https://apps.apple.com/br/app/hub3-gest%C3%A3o-de-investimentos/id6449549948";
    }
    if (EnvHelper.isInvestMe()) {
      return (
        <HeaderItem className="downloadApp" onClick={() => window.open(appLink, "_blank").focus()}>
          <span className="cta-link">{t('headerNoUser.downloadApp')}</span>
        </HeaderItem>
      )
    }

    return (
      <RightHeader>
        {(pathname.includes('/login')
        || pathname.includes('/password-recovery')
        || pathname.includes('/recuperacao-senha')
        || pathname.includes('/routing-password-recovery')) && (
          <HeaderItem className="dontHaveAccount" onClick={() => history.push(`/${language}/register`)}>
            <span>{t('headerNoUser.dontHaveAccount')}</span>{" "}<span className="cta-link">{t('headerNoUser.register')}</span>
          </HeaderItem>
        )}
        {pathname.includes('/register') && (
          <HeaderItem className="haveAccount" onClick={() => history.push(`/${language}/login`)}>
            <span>{t('headerNoUser.haveAccount')}</span>{" "}<span className="cta-link">{t('headerNoUser.doLogin')}</span>
          </HeaderItem>
        )}
      </RightHeader>
    )
  }
  const goToHome = () => {
    dispatch(setLoading(false));
    window.scrollTo({ top: 0, behavior: "smooth" });
    history.push(`/${language}/`);
  }
  const showSandwich =
   (pathname.includes("/home") || isExact || pathname.includes("/courses") || pathname.includes("/lives") || pathname.includes("/purchases"));

  const logoSize = () => {
    if (isMobile || isTablet) {
      return "164px";
    }

    return "220px";
  }
  const goBack = () => {
    const iframe = document.getElementById('playlistIframe');
    if (isPlaylist) {
      iframe.contentWindow.postMessage('home', '*');
    } else {
      pathname.match(regex) ?
      history.push(`/${language}/courses`)
      : history.goBack();
    }
  }
  return (
    <React.Fragment>
      <Container product={product} showHeader={showHeader} isInvestMe={EnvHelper.isInvestMe()}
        scrollBlock={scrollBlock} id="headerContainerRef" ref={headerContainerRef}
      >
        <LeftHeader  hide={inputOpen}>
          { isMobile && showSandwich && themeContext.var.showInHeader &&(
            <SandwichContainer onClick={() => {
              setUserMenuOpen(!isUserMenuOpen);
            }}>
              <SvgImage
                name="ia-menu-ic"
                width='24px'
                height='24px'
                color={themeContext.isWL ? themeContext.color.neutral.low.medium : null} />
            </SandwichContainer>
          ) }
          <LogoContainer>
            {themeContext.var.showInHeader ? <SvgImage
              onClick={() => goToHome()}
              name={"logo"}
              width={logoSize()}
              height={isMobile ? "32px" : "auto"}
            /> : pathname.includes("/checkout")
            || pathname.includes("/courselp")
            || (isPlaylist && inContent)
            || pathname.match(regex) ?
            <GoBackContainer onClick={() => goBack()}>
              <Svg name={"ic-arrow-left"} height={"24px"} width={"24px"} />
              {pathname.includes("/courselp") ?
                <h1>{courseLpName}</h1> :
                <HeaderTitleText style={{marginLeft: '8px'}}>{t('header.learnIt')}</HeaderTitleText>
              }
            </GoBackContainer>
            : <HeaderTitleText onClick={() => goToHome()}>{t('header.learnIt')}</HeaderTitleText>
            }
          </LogoContainer>
        </LeftHeader>
        {!pathname.includes("/checkout") &&
         !pathname.includes("/login") &&
         !pathname.includes("/register") &&
         !pathname.includes("/password-recovery") &&
         !pathname.includes("/recuperacao-senha") && (
          <CenterHeader>
            <InputSearch />
          </CenterHeader>
        )}
        {isCheckout
          ? checkoutRightSide()
          : isAuth
          ? authRightSide()
          : regularRightSide()}
        { !isMobile && !isLargeDesktop && showSandwich && themeContext.var.showInHeader && !isLoggedIn &&(
          <SandwichContainer onClick={() => {setUserMenuOpen(!isUserMenuOpen); }}>
            <SvgImage
              name="ia-menu-ic"
              width='24px'
              height='24px'
              color={themeContext.isWL ? themeContext.color.neutral.low.medium : null} />
          </SandwichContainer>
        ) }
      </Container>
      <Suspense fallback={<></>}>
        {(product === 'INVESTACADEMY' && isLoggedIn) && <MenuNotifications ref={notificationsRef} open={isNotificationsOpen} setOpen={setNotificationsOpen} isMenu />}
        <MenuUser ref={userMenuRef} open={isUserMenuOpen} setOpen={setUserMenuOpen} bgColor={themeContext.isWL ? themeContext.color.neutral.low.dark : null} isMenu />
      </Suspense>
    </React.Fragment>
  );
}

export default Header;
