const itemDev = [
  {
    name: "home",
    to: "home",
    text: "nav.home",
    icon: "ia-home-ic-outlined",
    iconActive: "ia-home-ic-filled",
  },
  {
    name: "courses",
    to: "courses",
    text: "nav.myCourses",
    icon: "ia-cap-ic",
    iconActive: "ia-cap-ic-fill",
  },
  {
    name: "playlists",
    to: "",
    link:"http://localhost:8080/?canUserAccess=true",
    text: "nav.playlists",
    icon: "",
    iconActive: "",
  },
  {
    name: "lives",
    to: "lives",
    text: "nav.lives",
    icon: "ia-live-ic",
    iconActive: "ia-live-ic-fill",
  },
];

const itemProd = [
  {
    name: "home",
    to: "home",
    text: "nav.home",
    icon: "ia-home-ic-outlined",
    iconActive: "ia-home-ic-filled",
  },
  {
    name: "courses",
    to: "courses",
    text: "nav.myCourses",
    icon: "ia-cap-ic",
    iconActive: "ia-cap-ic-fill",
  },
  {
    name: "playlists",
    to: "",
    link:"http://localhost:8080/?canUserAccess=true",
    text: "nav.playlists",
    icon: "",
    iconActive: "",
  },
  {
    name: "lives",
    to: "lives",
    text: "nav.lives",
    icon: "ia-live-ic",
    iconActive: "ia-live-ic-fill",
  },
];

const navMenuItens = process.env.REACT_APP_ENVIRONMENT === 'development' ? itemDev : itemProd;
export default navMenuItens;
