export default class LanguageHelper {
  static LANGUAGES = [
    {
      id: 0, // (sim, é zero)
      code: 'pt-BR',
      i18nCode: 'pt',
    },
    {
      id: 1,
      code: 'en-US',
      i18nCode: 'en',
    },
    {
      id: 2,
      code: 'es-ES',
      i18nCode: 'es',
    },
  ];

  static LANGUAGES_MAP = {
    'pt-BR': 0,
    pt: 0,
    'en-US': 1,
    en: 1,
    'es-ES': 2,
    es: 2,
  };

  static getLanguageByCode(code) {
    const index = this.LANGUAGES_MAP[code];
    if (index === undefined) return '';
    return this.LANGUAGES[index];
  }

  static getLanguageById(id) {
    const langId = parseInt(id, 10);
    return this.LANGUAGES.find((lang) => lang.id === langId);
  }

  static getDefaultAppLanguage(defaultLang) {
    const lang = window.navigator.language || defaultLang;
    return this.getLanguageByCode(lang);
  }

  static getUrlLanguageCode() {
    const params = new URLSearchParams(document.location.search);
    return params.get('lang') || '';
  }

  static getUrlLanguage() {
    const urlLang = this.getUrlLanguageCode();
    let urlCode = '';
    if (urlLang) urlCode = this.getLanguageByCode(urlLang);
    return urlCode;
  }
}
