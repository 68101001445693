import CryptoJS from 'crypto-js';
import StorageHelper from "../helpers/StorageHelper";
import { userApi } from "./user";
import UrlHelper from "../helpers/UrlHelper";
import UserHelper from "../helpers/UserHelper";
import EnvHelper from "../helpers/EnvHelper";
import RequestHelper from "../helpers/RequestHelper";
import jwt from 'jwt-decode';
import i18n from 'i18next';
import CountryHelper from '@common/classes/helpers/CountryHelper';
import LanguageHelper from '@common/classes/helpers/LanguageHelper';

const _api = "auth/";
const nPlatformID = 4;
let _attempts = 0; // Controla as tentativas de refresh do token

export const envId = process.env.REACT_APP_WL_ENVIRONMENT_ID ?? null;

export const auth = {
  async authenticate(data) {
    try {
      const hashPassword = CryptoJS.SHA1(data.password);
      const hexPassword = hashPassword.toString(CryptoJS.enc.Hex);

      const resp = await RequestHelper.post(`${_api}login`, {
        username: data.username,
        password: hexPassword,
        token2FA: data.token2FA,
        method: envId ? "wl-educacional" : "site-educacional",
        strRecaptchaResponse: data.strRecaptchaResponse,
        nPlatformID,
        hardwareID: UserHelper.getHardwareID(),
        hashType: '1',
        environment: envId,
      });
      await userApi.setTokenOnLocalStorage(resp.data);
      return userApi.setUserData();
    } catch (error)  {
      if (error.name === 'AxiosError') {
        throw error.response;
      } else {
        throw new Error('erroInLogin');
      }
    }
  },

  loginPeer(token) {
    return RequestHelper.post('/users/create/integration/minimal', {
      token,
      bTermsAndPrivacyPolicyRead: true,
      createAccessToken: true,
      method: "wl-educacional",
      nBillingCountry: CountryHelper.BRAZIL_ID,
      nLanguage: LanguageHelper.getLanguageByCode(i18n.language).id,
      nTypePlatformID: 4,
      strMachineID: UserHelper.getHardwareID()
    }, false, null, {token})
  },

  checkForInitialInfo(peerObj = {}) {
    const queryParams = UrlHelper.getUrlQueryParams();

    const {
      token,
      expires_in = null,
    } = EnvHelper.isPeer() ? peerObj : queryParams;
    return this.loadInitialData(token, expires_in);
  },

  async loadInitialData(token, expires_in) {
    let loginUrl = 'auth/login/educational';
    let method = envId ? "wl-educacional" : "profit-educacional";

    if (EnvHelper.isInvestMe()) {
      loginUrl = 'auth/me';
      method = "app-b3";
    }

    try {
      if (token) {
        StorageHelper.setToLocalStorage('access_token', token);
        let retorno = EnvHelper.isInvestMe() ? await RequestHelper.get(loginUrl, true, null, null, {
          method
        }) : (method === "wl-educacional"
          ? await RequestHelper.get('auth/check', false)
          : await RequestHelper.post(loginUrl, {
            method
        }));
        if (EnvHelper.isInvestMe()) {
          retorno.data.access_token = token;
          retorno.data.expires_in = expires_in;
        } else if (method === "wl-educacional" && retorno.status === 200) {
          const dataToken = jwt(token);
          var date = new Date(dataToken.exp * 1000);
          var expiresIn = Math.abs(new Date().getTime() - date.getTime())/1000;
          retorno['data'] = {
            expires_in: Math.round(expiresIn),
            access_token: token,
          }
        }
  
        await userApi.setTokenOnLocalStorage(retorno.data);
      }

      return userApi.setUserData();

    } catch (error)  {
        return userApi.setUserData()
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.error(err, error);
            UserHelper.removeBearerToken();
          });
    }
  },

  isTokenExpired() {
    const timestamp = StorageHelper.getFromLocalStorage('timestamp_token');

    if(!timestamp || EnvHelper.isInvestMe()) {
      return false;
    }

    return Date.now() > timestamp;
  },

  getUserToken() {
    return StorageHelper.getFromLocalStorage('access_token');
  },

  setRefresh() {
    const token = this.getUserToken();
    if (!token) {
      return;
    }
    return setTimeout(() => this.refreshUserToken(token), 2000);
  },

  saveUserToken(data) {
    _attempts = 0;
    userApi.setTokenOnLocalStorage(data)
    this.setRefresh();
  },

  refreshUserToken(token) {
    if (!token) {
      return false;
    }

    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    const body = {
      hardwareID: UserHelper.getHardwareID(),
      nPlatformID,
    }
    return RequestHelper.post('/auth/refresh', body, headers)
      .then((response) => {
        _attempts = 0;
        this.saveUserToken(response.data);
        return true;
      })
      .catch(() => {
        _attempts++;
        if (_attempts < 5) {
          setTimeout(() => this.refreshUserToken(token), 2000);
        } else {
          return false;
        }
      })
  }
}
